<template>
  <view-item-setting>
    <template #operation>
      <en-button type="primary" @click="operation.add.click">添加险种项目</en-button>
    </template>

    <flex-box>
      <template #default="{ height }">
        <table-dynamic
          :height="height"
          code="IREFD"
          :data="table.data"
          :loading="table.loading"
          :paging="table.paging"
          :method="table.get"
          pagination
          :config="table.config"
        >
          <template #OPERATION="{ row }: { row: EnocloudCommonDefinitions['InsuranceDto'] }">
            <button-delete :method="table.operation.delete.click" :params="row">删除</button-delete>
          </template>
          <template #NAME="{ row }: { row: EnocloudCommonDefinitions['InsuranceDto'] }">
            <en-button type="primary" link @click="table.operation.name.click(row)">{{ row.name }}</en-button>
          </template>
        </table-dynamic>
      </template>
    </flex-box>
  </view-item-setting>

  <en-drawer v-model="detail.visible" :title="detail.form.data.id ? '编辑' : '添加' + '维修项目类别'">
    <en-form :model="detail.form.data" :rules="detail.form.rules" :loading="detail.form.loading" :ref="setRef('detailForm')">
      <en-form-item label="险种类型" prop="type.code">
        <select-maintain
          v-model="detail.form.data.type"
          :ajax="{ action: 'GET /enocloud/common/lookup/:lookupType', params: (params) => (params.paths = ['INSRCTYPE']) }"
          :props="{ label: 'message', value: '' }"
          :disabled="Boolean(detail.form.data.id)"
          value-key="code"
          class="w-full"
        ></select-maintain>
      </en-form-item>
      <en-form-item label="险种项目名称" prop="name">
        <en-input v-model="detail.form.data.name"></en-input>
      </en-form-item>
      <en-form-item label="项目编码">
        <en-input :model-value="detail.form.data.serialNo" disabled></en-input>
      </en-form-item>
    </en-form>

    <template #footer>
      <en-button @click="detail.footer.cancel.click">取消</en-button>
      <button-ajax :method="detail.footer.confirm.click">确定</button-ajax>
    </template>
  </en-drawer>
</template>

<script lang="ts">
export default factory({
  config: {
    children: {
      operation: {
        add: {
          click() {
            this.detail.form.init()
            this.detail.visible = true
          }
        }
      },
      table: {
        ajax: {
          get: {
            action: 'GET /enocloud/common/insurance',
            data: 'array',
            loading: true,
            pagination: true
          },
          delete: {
            action: 'DELETE /enocloud/common/insurance/:insuranceId',
            loading: true
          }
        },
        children: {
          operation: {
            delete: {
              async click(row: EnocloudCommonDefinitions['InsuranceDto']) {
                await this.table.delete({ paths: [row.id] })
                return this.table.get()
              }
            },
            name: {
              click(row: EnocloudCommonDefinitions['InsuranceDto']) {
                this.detail.form.init()
                this.detail.form.data.id = row.id
                this.detail.form.get()
                this.detail.visible = true
              }
            }
          }
        },
        config: {
          SERIAL_NO: { header: { filter: { type: 'text', field: 'serialNo' } } },
          NAME: { header: { filter: { type: 'text', field: 'name' } } },
          TYPE: {
            header: {
              filter: {
                type: 'select',
                field: 'type',
                props: {
                  ajax: {
                    action: 'GET /enocloud/common/lookup/:lookupType',
                    params: (params) => (params.paths = ['INSRCTYPE'])
                  },
                  props: { label: 'message', value: 'code' }
                }
              }
            }
          },
          GOODS_NAME: {
            header: {
              filter: {
                type: 'text',
                field: 'goodsName'
              }
            }
          },
          GOODS_OEM: {
            header: {
              filter: {
                type: 'text',
                field: 'goodsOem'
              }
            }
          },
          GOODS_SERIAL_NO: {
            header: {
              filter: {
                type: 'text',
                field: 'goodsSerialNo'
              }
            }
          },
          GOODS_CATEGORY_NAME: {
            header: {
              filter: {
                type: 'select',
                field: 'categoryId',
                props: {
                  ajax: { action: 'GET /enocloud/common/goods/category', params: (value) => ({ name: value }) },
                  props: { label: 'name', value: 'id' },
                  remote: true
                }
              }
            }
          },
          GOODS_VEHICLE_SPEC: { header: { filter: { type: 'text', field: 'goodsVehicleSpec' } } },
          WAREHOUSE_NAME: {
            header: {
              filter: {
                type: 'select',
                field: 'warehouseId',
                props: {
                  ajax: {
                    action: 'GET /enocloud/common/warehouse',
                    params: (params, value) => (params.payload = { name: value })
                  },
                  remote: true,
                  props: { label: 'name', value: 'id' }
                }
              }
            }
          },
          SUB_TYPE: { header: { filter: { type: 'text', field: 'inventoryHistory.subType' } } }
        }
      },
      detail: {
        visible: false,
        children: {
          form: {
            is: 'form',
            ajax: {
              get: {
                action: 'GET /enocloud/common/insurance/:insuranceId',
                data: 'object',
                loading: true,
                params(params) {
                  params.paths = [this.detail.form.data.id]
                }
              },
              submit: {
                action: 'POST /enocloud/common/insurance',
                loading: true,
                validate: true,
                params(params) {
                  params.payload = this.detail.form.data
                }
              },
              update: {
                action: 'PUT /enocloud/common/insurance',
                loading: true,
                validate: true,
                params(params) {
                  params.payload = this.detail.form.data
                }
              }
            },
            rules: {
              'type.code': [{ required: true, message: '请选择险种类型' }],
              name: [{ required: true, message: '请输入险种项目名称' }]
            }
          },
          footer: {
            cancel: {
              click() {
                this.detail.visible = false
              }
            },
            confirm: {
              async click() {
                await this.detail.form[this.detail.form.data.id ? 'update' : 'submit']()
                return this.table.get().then(() => (this.detail.visible = false))
              }
            }
          }
        }
      }
    }
  },

  mounted() {
    this.table.get()
  }
})
</script>
